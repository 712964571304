#root .ui.link.menu .item,
#root .ui.menu .link.item,
#root .ui.menu a.item {
    /* background-color: pink; */
}

#root .ui.card,
#root .ui.segment.card.fluid {
    box-shadow: none;
    transition: box-shadow none;
    border: 0;
}

#root .ui.card > .content {
    border: none;
}

#root .ui.card > .content,
#root .ui.cards > .card > .content {
    padding: 0 0 0.5em 0
}

ul.links {
    margin: 0;
    padding-left: 0
}

ul.links li {
    list-style-type: none;
}

#root h2,
#root h2 a,
#root h3,
#root h3 a {
    margin: 0.4rem 0 0.2rem 0;
    padding: 0;
    font-weight: normal;
}

#root .home-page-content h3 {
    padding-bottom: 1.2rem;
}


#root .ui.fixed.menu h3 {
    margin: 0 0.5em;
    display: flex;
    align-items: center;
}

#root .ui.card.matched.skill {
    border-top: 2px solid green !important;
}

#root .ui.card.matched.qualification {
    border-top: 2px solid lightskyblue !important;
}

#root a {
    color: #009933 !important
}


/* Home page grid */

.ui.grid > a {
    flex: 1 0 auto;
    height: auto;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3rem;
}

.ui.grid > a:before {
    content: '';
    float: left;
    padding-top: 100%;
}

#root .ui.grid.entry-grid .column {
    padding: 2px
}

#root .ui.grid.entry-grid .column > p {
    line-height: 0.8 !important;
    text-transform: uppercase;
    color: white !important;
    text-align: left;
    font-size: 2rem;
}

.skills {
    position: absolute;
    text-align: justify;
}

.skills p {
    font-style: italic;
    font-family: serif;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.1);
    line-height: 0.4 !important;
}


/* Home page search button */

.home-page {
    background-color: #d4ecef;
}

.home-page-search {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

#root .home-page-search a {
    display: block;
    border-radius: 200px;
    width: 200px;
    line-height: 200px;
    background: #111;
    color: white !important;
    font-size: 2rem;
    text-align: center;
    z-index: 1000;
}

#root .home-page-search a:hover {
    background: #000;
    color: yellow !important;
}


/* Home page blurb */

.home-page-content {
    display: flex !important;
    align-items: center;
}


/* Search page input and dropdowns */

.list-tools {
    background-color: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
}
.list-tools .search,
.list-tools > .sort {
    padding: 10px;
}
.list-tools .input,
.list-tools .dropdown {
    width: 100%;
    margin-bottom: 2px;
}


/* Entry page header */
.page-header {
    background-image: url("images/page-header-no-logos.png");
    background-position: bottom left;
    background-size: cover;
    height: 150px;
    display: flex;
    align-items: center;
}
.page-header .links {
    display: flex;
    align-items: center;
}
#root .page-header .links a {
    background-color: rgba(0,0,0,0.8);
    color: white !important;
    font-size: 2rem;
    margin-right: 1em;
}
#root .page-header .links a:hover {
    background-color: rgb(0,0,0);
    color: yellow !important;
}
.list-tools-fake-bgd {
    background-image: url("images/page-header-no-logos.png");
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
}

.page-header .links .home {
    background-color: black;
    padding: 1em;
}

.page-header .links .search {
    width: 100px;
    height: 100px;
    margin-bottom: 2em;
}
.page-header .links .search a {
    display: block;
    border-radius: 120px;
    width: 120px;
    line-height: 120px;
    color: white !important;
    text-align: center;
}

.entry-list-page .page-header .links .search {
    display: none;
}