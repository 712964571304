/* Possibly useful things

overflow-y: scroll;
-webkit-overflow-scrolling: touch;
*/
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.7em !important;
    font-size: 16px;
}

html,
body,
#root {
    height: 100%;
}

#root {
    width: 100%;
    position: relative;
    display: flex;
}

.entry-list-page .entry {
    padding-bottom: 1em !important;
    margin-bottom: 1.5em !important;
}

.tags {
    display: flex;
    flex-wrap: wrap;
}

.tags label {
    flex: 0 0 50px;
    margin-right: 0.5em;
}

ul.tags {
    flex: 1 0 50px;
    margin: 0;
    padding-left: 0;
}

ul.tags li {
    display: inline;
    margin-right: 1em;
}


.home-page {
    margin: 0;
    width: 100%;
}

a,
a:visited {
    color: rgb(158, 55, 38) !important
}

hr {
    background-color: rgba(158, 55, 38, 0.4);
    border: 0 none;
    color: rgba(158, 55, 38, 0.4);
    height: 1px !important;
}

hr.padded {
    margin: 4em;
}

.dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

@media only screen and (max-width: 767px) {
    .ui.container.entry-list-page,
    .ui.container.entry-page {
        width: auto !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.list-tools {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}

.list-tools div {
    /* border: 1px solid pink */
    margin-right: 0.4em
}

.list-tools .search {
    flex: 1 0 80px;
}

.list-tools .sort {
    flex: 0 0 80px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.list-tools .sort button {
    flex-grow: 0;
}

.content p {
    max-width: 40em;
}

#root .credits {   padding: 2em; display:flex; }
.creditsImage{height:150px; width:251px; margin: 0 1em 1em 0;}
/* fix iOS input zooming */

@media screen and (max-width: 767px) {
    input,
    select,
    textarea {
        font-size: 16px !important;
    }
    #root .credits {flex-direction: column; align-items: center; text-align: center;}
    .creditsImage{margin: 0 0 1em 0;}
}
